<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>交易查询</el-breadcrumb-item>
          <el-breadcrumb-item>账户查询</el-breadcrumb-item>
          <el-breadcrumb-item>账户信息</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block top">
      <el-row class="total-header">
        <el-col :span="span4" class="span4">
          <el-row>
            <el-col :span="span12">用户总数:</el-col>
            <el-col :span="span10"><span>{{ total.userCount }}</span></el-col>
          </el-row>
        </el-col>
        <el-col :span="span4" class="span4">
          <el-row>
            <el-col :span="span12">入账总金额:</el-col>
            <el-col :span="span10"><span>{{ total.totalCredit }}</span></el-col>
          </el-row>
        </el-col>
        <el-col :span="span4" class="span4">
          <el-row>
            <el-col :span="span12">出账总金额:</el-col>
            <el-col :span="span10"><span>{{ total.totalDebit }}</span></el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="账户类型">
              <el-select v-model="where.accountType" placeholder="请选择账户类型">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item,index) in accountType" :key="index" :label="item.accountType"
                           :value="item.accountTypeCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账户角色">
              <el-select v-model="where.ownerRole" placeholder="请选择账户角色">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item,index) in ownerRole" :key="index" :label="item.ownerRole"
                           :value="item.ownerRoleCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账户状态">
              <el-select v-model="where.status" placeholder="请选择账户状态" >
                <el-option label="全部" value=""></el-option>
                <el-option label="正常" value="NORMAL"></el-option>
                <el-option label="失效" value="INVALID"></el-option>
                <el-option label="冻结" value="FREEZE"></el-option>
                <el-option label="冻结止付" value="FREEZE_PAYMENT"></el-option>
                <el-option label="冻结止收" value="FREEZE_RECEIPT"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="where.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()">条件筛选</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12">

            </el-col>
            <el-col :span="12">
              <el-button
                  type="primary"
                  @click="exportData"
                  disabled
                  icon="el-icon-tickets">导出数据
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
            :data="list"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.1)"
        >
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="ownerId" label="持有人ID"></el-table-column>
          <el-table-column prop="ownerRole" label="持有人角色">
            <template slot-scope="scope">
              <span :class="scope.row.ownerRoleCode">{{ scope.row.ownerRole }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="accountType" label="账户类型">
            <template slot-scope="scope">
              <span :class="scope.row.accountTypeCode">{{ scope.row.accountType }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="账户状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status =='NORMAL'" style="color: green">正常</span>
              <span v-if="scope.row.status =='INVALID'" style="color: red">失效</span>
              <span v-if="scope.row.status =='FREEZE'" style="color: red">冻结</span>
              <span v-if="scope.row.status =='FREEZE_PAYMENT'" style="color: red">冻结止付</span>
              <span v-if="scope.row.status =='FREEZE_RECEIPT'" style="color: red">冻结止收</span>
            </template>
          </el-table-column>
          <el-table-column prop="ownerName" label="持有人名称"></el-table-column>
          <el-table-column prop="accountNo" label="账户编号"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="balance" label="余额"></el-table-column>
          <el-table-column prop="cashQuota" label="提现额度"></el-table-column>
          <el-table-column prop="freezeQuota" label="冻结额度"></el-table-column>
          <el-table-column prop="cutoffFlag" label="是否转结账户">
            <template slot-scope="scope">
              {{ scope.row.cutoffFlag === 'N' ? '否' : '是' }}
            </template>
          </el-table-column>
          <el-table-column prop="totalCredit" label="累计入账金额"></el-table-column>
          <el-table-column prop="totalDebit" label="累计出账金额"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column label="操作" fixed="right" width="220px">
            <template slot-scope="scope">
              <!--<el-button type="primary" class="el-icon-document-checked" @click="$router.push({name: 'accountBillListTo',query: { accountNo: scope.row.accountNo }})">账户流水
              </el-button>-->
              <el-button type="primary" class="el-icon-document-checked" @click="$router.push({name: 'accountHistoryList',query: { accountId: scope.row.id }})">历史记录
              </el-button>
              <el-button type="primary" class="el-icon-document-checked" @click="editStatusVisiable(scope.row)">修改状态
              </el-button>
            </template>
            <!--<template slot-scope="scope">-->
            <!--<el-dropdown>-->
            <!--<span class="el-dropdown-link">-->
            <!--操作<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
            <!--</span>-->
            <!--<el-dropdown-menu slot="dropdown">-->
            <!--<el-dropdown-item icon="el-icon-notebook-2">-->
            <!--<router-link :to="{-->
            <!--path: '/Admin/merchant/shop/shoplist',-->
            <!--query: {-->
            <!--id: scope.row.id,-->
            <!--},-->
            <!--}">-->
            <!--门店列表-->
            <!--</router-link>-->
            <!--</el-dropdown-item>-->
            <!--</el-dropdown-menu>-->
            <!--</el-dropdown>-->
            <!--</template>-->
          </el-table-column>
        </el-table>
        <div class="admin_table_main_pagination">
          <el-pagination
              @current-change="currentChange"
              @size-change="handleSizeChange"
              background
              :total="totalData"
              :page-size="pageSize"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
    </div>

    <el-dialog title="账户信息" :visible.sync="dialogFormVisible" width="550px">
      <el-form :model="updateForm">
        <el-form-item label="账户编号" label-width="120">
          <el-input v-model="updateForm.accountNo" :disabled="true" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="账户状态" label-width="150">
          <el-select v-model="updateForm.status" placeholder="请选择账户状态" style="width: 100%">
            <el-option label="正常" value="NORMAL"></el-option>
            <el-option label="失效" value="INVALID"></el-option>
            <el-option label="冻结" value="FREEZE"></el-option>
            <el-option label="冻结止付" value="FREEZE_PAYMENT"></el-option>
            <el-option label="冻结止收" value="FREEZE_RECEIPT"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateStatusSubmit()">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  components: {},
  props: {
    state: Number,
  },
  data() {
    return {
      span: 6,
      span4: 5,
      span12: 12,
      span10: 10,
      list: [],
      date: "",
      row: {},
      loading: false,
      dialogDetailVisible: false,
      dialogEditVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      ownerRole: [],
      accountType: [],
      where: {
        pageNum: 0,//分页，页数
        pageSize: 0,//分页，每页大小
        ownerRole: '',//账户持有人角色【PERSON-普通用户  BD-BD  OPERATION_CENTER-运营中心  SERVICE_PROVIDER-服务商  SHOP-门店  SYSTEM-系统人员】
        accountType: '',//账户类型【CON_VAL-贡献值  PROFIT_PAY-分润现金  PROFIT_BEAN-分润惠豆  AD_BEAN-广告惠豆  AD_PAY-广告现金】
        cutoffFlag: '',//是否转结账户【Y：是  N：否】
        phone: ''    // 手机号
      },
      total: {
        userCount: 0,    // 账户总数
        totalCredit: 0,  // 进账总金额
        totalDebit: 0,   // 出账总金额
      },
      dialogFormVisible: false,
      updateForm: {},
    };
  },
  methods: {
    editStatusVisiable(row) {
      console.log(row);
      this.updateForm = {
        id: row.id,
        accountNo: row.accountNo,
        status: row.status,
      };
      this.dialogFormVisible = true;
    },
    updateStatusSubmit() {
      console.log(this.updateForm);
      this.$post(this.$api.accountStatusUpdate, {
        id: this.updateForm.id,
        status: this.updateForm.status
      }).then(res => {
        console.log(res);
        // eslint-disable-next-line no-empty
        if (res.code != 1) {
          this.$message.error(res.msg);
          this.dialogFormVisible = false;
        } else {
          this.listData();
          this.dialogFormVisible = false;
        }
      });

    },
    listData() {
      this.loading = true;
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.$post(this.$api.accountListInfo, this.where).then((res) => {
        this.totalData = res.data.total;
        this.list = res.data.records;
        this.loading = false;
        this.getTotal();
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    exportData() {
      // console.log("exportData");
    },
    getGwnerRole() {
      this.$post(this.$api.getOwnerRoleAll, {}).then((res) => {
        if (res.code === 1) {
          this.ownerRole = res.data;
        }
      });
    },
    getAccountType() {
      this.$post(this.$api.getAccountTypeAll, {}).then((res) => {
        if (res.code === 1) {
          this.accountType = res.data;
        }
      });
    },
    getTotal() {
      this.$post(this.$api.accountStatistics, this.where).then((res) => {
        if (res.code === 1) {
          this.total = res.data;
        }
      });
    },
  },
  created() {
    this.listData();
    this.getAccountType();
    this.getGwnerRole();
    this.getTotal();
  },
};
</script>
<style lang="scss" scoped>
.total-header {
  margin-bottom: 0px !important;
  margin-top: 15px;

  .el-col {
    margin-right: 15px;

    :last-child {
      margin-right: 0px;
    }
  }
}

.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.header-form {
  height: 40px;
  padding: 10px 0 10px 0;
}

.span4 {
  background: rgb(217, 236, 255);
  font-size: 16px;
  padding: 30px 0;
  text-align: center;
  vertical-align: center;

  span {
    font-size: 18px;
  }
}

.endDate {
  color: #c0c4cc;
}

.el-image {
  height: 100px;
  width: 100px;
}

.el-select {
  width: 120px;
}

.SYSTEM, .PROFIT_PAY {
  color: #67C23A;
}

.SHOP, .PROFIT_PAY {
  color: #E6A23C;
}

.SERVICE_PROVIDER, .PROFIT_BEAN {
  color: #F56C6C;
}

.PERSON, .CON_VAL {
  color: #909399;
}

.OPERATION_CENTER, .AD_PAY {
  color: #409EFF;
}

.OPERATION_CENTER, .AD_PAY {
  color: #a84a26;
}
</style>
<style>
.el-drawer__header span:focus {
  outline: 0 !important;
}
</style>
