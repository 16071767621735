import { render, staticRenderFns } from "./money.vue?vue&type=template&id=3f0d3cd2&scoped=true&"
import script from "./money.vue?vue&type=script&lang=js&"
export * from "./money.vue?vue&type=script&lang=js&"
import style0 from "./money.vue?vue&type=style&index=0&id=3f0d3cd2&lang=scss&scoped=true&"
import style1 from "./money.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0d3cd2",
  null
  
)

export default component.exports